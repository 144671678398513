











import { Vue, Component } from 'vue-property-decorator';
import { IntegrationService } from '@/lib/services';
import { Integration } from '@/models';

import { IntegrationCard } from '@/components/integration';

@Component({
  components: {
    IntegrationCard,
  },
})
export default class IdealSetup extends Vue {
  mollie: Integration | null = null;

  async mounted(): Promise<void> {
    await this.loadMollie();
  }

  async loadMollie(): Promise<void> {
    this.mollie = await IntegrationService.get('mollie');
  }
}
