



























import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { CustomerService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

@Component
export default class VatChecker extends Vue {
  @Model('input')
  vatNumber: string;

  @Prop({ required: false, type: String })
  country: string;

  loading: boolean = false;
  error: string = '';

  onInput(event: Record<string, any>): void {
    this.$emit('input', event.target.value);
  }

  async lookup(): Promise<void> {
    if (!this.vatNumber || this.vatNumber.length < 2) {
      return;
    }

    this.loading = true;
    this.error = '';
    const possibleCountryCode = this.vatNumber.slice(0, 2);

    let country = this.country;
    let vatNumber = this.vatNumber;
    if (/[a-z]{2}/i.test(possibleCountryCode)) {
      // VAT number includes country
      country = possibleCountryCode.toUpperCase();
      vatNumber = this.vatNumber.slice(2);
    }

    try {
      // vatNumber cannot be null at this point
      const result = await CustomerService.checkVat(country, vatNumber);
      this.$emit('results', result);
    } catch (e) {
      this.error = unwrapError(e);
    } finally {
      this.loading = false;
    }
  }
}
