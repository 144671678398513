




import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class FinishSetup extends Vue {
  async mounted(): Promise<void> {
    await this.$router.push({ name: 'invoice_create' });
  }
}
