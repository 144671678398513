

























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EmailAddress, EmailText, Profile } from '@/models';
import Quill from '@/components/Quill.vue';
import { EmailAddressService, EmailTextService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    Quill,
  },
})
export default class EmailSetup extends Vue {
  @Prop({ required: true })
  profile!: Profile;

  fromData = {
    address: '',
    loading: false,
    done: false,
    type: 'from',
  };

  ccData = {
    address: '',
    loading: false,
    done: false,
    type: 'cc',
  };

  loadingEmail: boolean = true;
  savingEmail: boolean = false;
  emailText: EmailText | null = null;

  async mounted(): Promise<void> {
    try {
      this.emailText = await EmailTextService.get(+this.profile.emailTextId);
      this.loadingEmail = false;
    } catch (e) {
      this.$toaster.error('Error', unwrapError(e));
    }
  }

  async verify(data: Record<string, any>): Promise<void> {
    if (!data.address) {
      return;
    }

    data.loading = true;
    try {
      const addr = new EmailAddress();
      addr.address = data.address;
      addr.type = data.type;
      await EmailAddressService.create(addr);
      data.done = true;
    } catch (e) {
      this.$toaster.error('Error', unwrapError(e));
      data.loading = false;
    }
  }

  async saveText(): Promise<void> {
    if (!this.emailText) {
      return;
    }

    try {
      this.savingEmail = true;
      this.emailText = await EmailTextService.update(this.emailText);
      this.$toaster.success('Email text saved');
    } catch (e) {
      this.$toaster.error('Email editing failed', unwrapError(e));
    } finally {
      this.savingEmail = false;
    }
  }
}
