





































import { Component, Vue } from 'vue-property-decorator';

import { Step, Stepper } from '@/components/stepper';
import { ProfileLogoUpload } from '@/components/profile';
import * as SetupSteps from '@/components/setup';
import { ProfileService } from '@/lib/services';
import { Profile } from '@/models';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    Stepper,
    Step,
    ProfileLogoUpload,
    ...SetupSteps,
  },
})
export default class SetupPage extends Vue {
  loaded: boolean = false;
  profile: Profile | null = null;

  $refs: {
    stepper: Stepper;
  };

  async mounted(): Promise<void> {
    try {
      const profiles = await ProfileService.all();
      this.profile = profiles.find((e) => e.isDefault) || profiles[0];
      this.loaded = true;
    } catch (e) {
      this.$toaster.error('Error', unwrapError(e));
    }

    if (this.$route.query['step']) {
      const num = +this.$route.query['step'];
      if (num === 0) {
        return;
      }

      // Select step on next tick, as the stepper component hasn't been loaded
      // yet (as we are still in the tick that we are changing loaded to true)
      Vue.nextTick(() => this.$refs.stepper.select(num - 1));
    }
  }

  async saveAndStep(profile: Profile): Promise<void> {
    try {
      this.profile = await ProfileService.update(
        profile.id,
        profile.serialize(),
      );
      this.$refs.stepper.next();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.profile'),
        unwrapError(e),
      );
    }
  }
}
