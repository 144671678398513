

























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VatChecker from '@/components/VatChecker.vue';
import ProfileEditDetails from '@/components/profile/ProfileEditDetails.vue';
import { Profile } from '@/models';
import { CheckVatResponse } from '@/lib/services/customer';

@Component({
  components: {
    ProfileEditDetails,
    VatChecker,
  },
})
export default class ProfileSetup extends Vue {
  @Prop({ required: true })
  profile!: Profile;

  vatNumber: string = '';
  country: string = '';
  editing: Profile | null = null;

  fields = [
    ['companyname', 'lineCompanyName'],
    ['contact', 'lineContact'],
    ['address', 'lineAddress'],
    ['cityzipcode', ['linePostalcode', 'lineCity']],
    ['country', 'lineCountry'],
    ['break', null],
    ['vatnumber', 'lineVATNumber'],
    ['iban', 'lineIBAN'],
    ['break', null],
    ['phone', 'linePhoneNumber'],
    ['email', 'lineEmail'],
    ['extra', 'lineExtra'],
  ];

  mounted(): void {
    this.editing = Object.assign(new Profile(), this.profile);
  }

  onResult(result: CheckVatResponse): void {
    if (!this.editing) {
      return;
    }

    this.editing.lineCompanyName = result.name;
    this.editing.lineAddress = result.street;
    this.editing.linePostalcode = result.zip;
    this.editing.lineCity = result.city;
    this.editing.lineVATNumber = result.country + result.number;

    if (this.$te('_.country.' + result.country)) {
      this.editing.lineCountry = this.$tc('_.country.' + result.country);
    } else {
      this.editing.lineCountry = result.country;
    }
  }

  save(): void {
    this.$emit('save', this.editing);
  }
}
